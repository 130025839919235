.Link{
	font-size: 0.8rem;
	opacity: 0.8;
	color: #fff;
	text-decoration: none;
	margin-right: 1rem;
}

.Link:hover {
	opacity: 1;
	color: #fff;
	text-decoration: none;	
	cursor: pointer;
}