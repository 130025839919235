.CTAContainer{
	border-radius: 0.5rem;
	padding: 1rem 1.5rem;
	background-color: #D0DCE1;
	display: block;
}

@media only screen and (min-width: 992px) {
  .CTAContainer {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}