.Hero {
	background: url('../../../../assets/images/backgrounds/hero1.webp');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	min-height: 100vh;
	position: relative;
}

.Question {
	font-size: 0.9rem;
	font-weight: 300;
	color: #dee2e6;
	margin-bottom: 0.5rem;
}

.Header {
	font-size: 2.25rem;
	font-weight: 300;
	line-height: 1.25;
	color: #dee2e6;
	text-shadow: 0 0 1px #212529;
}

.PrimaryCTAWrapper {
	margin: 3rem 0;
}

.BottomCTAWrapper {
	position: absolute;
	width: 100%;
	bottom: 50px;
}

@media only screen and (max-width: 767px) {
	.PrimaryCTAWrapper {
		position: absolute;
		width: 100%;
		bottom: 150px;
	}
}

@media only screen and (min-width: 425px) {
	.Hero {
		background-position: left;
	}
}

@media only screen and (min-width: 992px) {
  .BottomCTAWrapper  {
		bottom: 25px;
	}
}

@media only screen and (min-width: 992px) {
  .Header {
		font-size: 2.95rem;
	}
}