.Why {
	background-color: #F8F9FA;
	padding: 4rem 0;
}

.Question {
	font-size: 0.9rem;
	font-weight: 300;
	margin-bottom: 0.25rem;
}

.Header {
	font-size: 2.5rem;
	font-weight: 500;
	line-height: 1.25;
	margin-bottom: 2rem;
}

.CTATeaser {
	font-size: 1.75rem;
}