.ScrollCTA, .ScrollCTA:active, .ScrollCTA:focus {
	color: #D44C3F !important;
	text-decoration: none !important;
	opacity: 0.8;
	font-size: 0.9rem !important;
	text-shadow: 0 0 1px #212529 !important;
	padding: 0 !important;
	outline: none !important;
	box-shadow: none !important;
}

.ScrollCTA:hover{
	opacity: 1;
}