.Tab {
	background: #DEE2E6;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.4rem;
	padding: 0.75rem 1.75rem;
	color: #6C757D;
}

.Tab:first-child{
	border-radius: 0.3rem 0 0 0.3rem;
}

.Tab:last-child{
	border-radius: 0 0.3rem 0.3rem 0;
}

.Active{
	background: #459CC1;
	color: #fff;
}

.Tab:hover{
	cursor: pointer;
}