.Pricing {
	background-color: #EAEEF0;
	padding: 4rem 0;
}

.Question {
	font-size: 0.9rem;
	font-weight: 300;
	margin-bottom: 0.25rem;
}

.Header {
	font-size: 2.5rem;
	font-weight: 500;
	line-height: 1.25;
	margin-bottom: 2rem;
}

.Contact, .Contact:active, .Contact:focus {
	text-decoration: none !important;
	color: #D44C3F;
}

.Contact:hover{
	text-decoration: none !important;
	color: #E12D1C;
}

