.Icon {
	padding-right: 0.4rem;
}

.Title {
	font-weight: 400;
	font-size: 2rem;
	margin-bottom: 0;
}

.Answer {
	margin-bottom: 0;
	margin-top: 0.75rem;
}
