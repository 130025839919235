.Plan {
	display: flex;
	flex-flow: column;
	border-radius: 0.5rem;
	padding: 1.5rem 1.5rem;
	background-color: #D0DCE1;
	max-width: 375px;
	margin: 0 auto;
	margin-bottom: 1rem;
}

.Title {
	margin: 0;
}

.Description {
	font-style: italic;
	font-weight: 300;
	font-size: 1.2rem;
	margin: 1rem 0 2rem 0;
}

.Prices {
	padding: 1rem 0;
	border-bottom: 2px solid #D44C3F;
	border-top: 2px solid #D44C3F;
}

.ActivePrice{
 font-size: 3.5rem;
 font-weight: 400;
}

.InactivePrice {
	margin: 0;
	text-decoration: line-through;
	text-decoration-color: #D44C3F;
	font-weight: 400;
}

.FeatureList {
	margin: 1.5rem 0;
	padding: 0;
	list-style: none;
}

.Feature {
	margin-bottom: 0.75rem;
	line-height: 1.3;
}

.NoCard {
	font-size: 0.8rem;
	margin: 0.5rem 0 0 0;
}

@media only screen and (min-width: 768px) {
  .Plan  {
		margin: 0 1.5rem;
	}
}