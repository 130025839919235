.IntroPricing {
	display: flex;
	align-items: center;
	border-radius: 0.5rem;
	padding: 1rem 1.5rem;
	background-color: #CFE2FF;
}

.Message {
	margin-bottom: 0;
	margin-left: 1rem;
	font-size: 1.4rem;
	line-height: 1.3;
	font-weight: 300;
	color: #084298;
}