.Step {
	background-color: #fff;
	position: relative;
	border-radius: 0.3rem;
	padding: 3rem 1rem 1rem 1rem;
	margin-bottom: 3rem;
}

.Number {
	position: absolute;
	top: -32px;
	left: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 64px;
	width: 64px;
	color: #fff;
	font-size: 3rem;
	background-color: #DFA59F;
	border-radius: 100%;
}

@media only screen and (min-width: 768px) {
  .Step  {
		margin-bottom: 0;
		margin-right: 1rem; 
	}
}