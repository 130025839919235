.Logo{
	opacity: 0.9;
	height: 30px;
}

.Logo:hover{
	opacity: 1;
}

.DefaultSize {
	width: 182px;
	height: 39px;
}

.Small {
	width: 130px;
	height: 28px;
}