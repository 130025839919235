.FormLayout {
	background: #EFF2F5; 
	min-height: 100vh;
}

.BackButton{
	color: #D44C3F !important;
	text-decoration: none !important;
	opacity: 0.8;
}

.BackButton:hover{
	opacity: 1;
}